import React, { useEffect, useState } from 'react'
import firebase from 'gatsby-plugin-firebase'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import * as queryString from "query-string"

import Layout from '../components/layout'
import Account from '../components/account'
import Contacts from '../components/contacts'
import Footer from '../components/footer'
import Navbar from '../components/navbar'

import { RowMixin, ColMixin } from '../components/elements'


const Col = styled.div`
  ${ColMixin}
`


const Row = styled.div`
  ${RowMixin}

  padding-bottom: 90px;
  padding-top: 90px;
  text-align: center;

  h4 {
    font-family: 'Righteous', cursive;
    font-size: 36px;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  p {
    font-size: 26px;
  }
`


const ConfirmPage = (props) => {
  const [ isConfirmed, setIsConfirmed ] = useState(false)
  const [ isError, setIsError ] = useState(false)

  useEffect(() => {
    const { key } = queryString.parse(props.location.search)
    firebase
      .firestore()
      .collection('submissions')
      .doc(key)
      .update({
        isConfirmed: true,
        confirmedAt: new Date()
      })
      .catch(() => {
        setIsError(true)
      })
      .then(() => {
        setIsConfirmed(true)
      })

  }, [])

  return <Layout
    title="Schaeffler Wielka Gra"
    description=""
  >
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Righteous&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <Navbar />

    <Row>
      <Col>
        {isConfirmed && <>
          <h4>Dziękujemy za potwierdzenie!</h4>
          <p>Dziękujemy! Twoje zgłoszenie zostało potwierdzone. Oczekuj na dalsze wiadomości od nas dotyczące wysyłki nagrody.</p>
        </>}

        {(!isConfirmed && isError) && <>
          <h4>Wystąpił błąd</h4>
          <p>Sprawdź czy kliknąłeś poprawny link z potiwerdzenia e-mail.</p>
        </>}
      </Col>
    </Row>

    <Account />
    <Contacts />
    <Footer />
  </Layout>
}

export default ConfirmPage
